<auth-base-container title="Forgot password">
  <form
    *ngIf="!showEmailSentMessage"
    class="form"
    [formGroup]="formGroup"
    (submit)="submit()"
    content
  >
    <mat-form-field
      floatLabel="always"
      appearance="fill"
      color="accent"
      class="text-input"
    >
      <mat-label>
        <span>Email</span>
      </mat-label>
      <input
        matInput
        formControlName="email"
      >
      <mat-error *ngIf="email.errors?.required">
        Email is required
      </mat-error>
      <mat-error *ngIf="email.errors?.email">
        Wrong email format
      </mat-error>
    </mat-form-field>

    <div class="submit">
      <ros-button
        type="submit"
        data-id="submit"
        [isDisabled]="sendingRecoveryEmail$ | async"
        [isLoading]="(sendingRecoveryEmail$ | async) || false"
        size="Large"
        width="Wide"
        title="Submit"
      ></ros-button>
    </div>
  </form>

  <div
    *ngIf="showEmailSentMessage"
    class="email-sent"
    content
  >
    <span class="email-sent__header">Email sent!</span>
    <span class="email-sent__message">If there is an account associated to this email, you will receive a password reset
      link.</span>
    <ros-button
      title="Back To Login"
      routerLink="/admin"
      size="Large"
      width="Wide"
    >
    </ros-button>
  </div>

  <a
    routerLink="/admin"
    *ngIf="!showEmailSentMessage"
    footer
  >
    <span>Want to Log in?</span>
    <span>Take me back</span>
  </a>
</auth-base-container>
