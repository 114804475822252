import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmDialogComponent {
  public dialogText: string;
  public dialogSubtitle: string;
  public dialogDescription: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: {
      title: string,
      withDenyButton: boolean
      subtitle?: string,
      description?: string,
    },
  ) { }

  public confirm(): void {
    this.dialogRef.close(true);
  }

  public deny(): void {
    this.dialogRef.close(false);
  }
}
