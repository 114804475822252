import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers/index';
import { authActions } from '@store/actions';
import { getValidatingRecoveryCode } from '@store/selectors';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { clearTOTPAuthToken } from '@store/actions/auth.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'recovery-auth-login',
  templateUrl: './recovery-login.component.html',
  styleUrls: ['./recovery-login.component.scss'],
})
export class RecoveryLoginComponent {
  public validationRecoveryCode$ = this.store.select(getValidatingRecoveryCode);

  public get recoveryCode(): AbstractControl {
    return this.recoveryLoginForm.get('recoveryCode');
  }

  public recoveryLoginForm: FormGroup = this.formBuilder.group({
    recoveryCode: ['', [
      Validators.required,
      Validators.maxLength(100),
    ]],
  });

  private recoveryCodeValidated = false;
  private destroy$ = new Subject<void>();

  constructor(
    protected store: Store<AppState>,
    private formBuilder: FormBuilder,
    private actions$: Actions,
    private router: Router,
  ) {
    this.actions$.pipe(
      ofType(authActions.validateRecoveryCodeFail),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.recoveryLoginForm.reset();
    });

    this.actions$.pipe(
      ofType(authActions.validateRecoveryCodeSuccess),
      takeUntil(this.destroy$),
    ).subscribe(() => {
      this.recoveryCodeValidated = true;
    });
  }

  public ngOnDestroy(): void {
    const currentNavigation = this.router.getCurrentNavigation();
    const targetUrl = currentNavigation?.finalUrl?.toString();

    if (!this.recoveryCodeValidated && (!targetUrl || !targetUrl.includes('/authentication-code'))) {
      this.store.dispatch(clearTOTPAuthToken());
    }

    this.destroy$.next();
    this.destroy$.complete();
  }

  public submit(): void {
    this.store.dispatch(authActions.validateRecoveryCode({ recoveryCode: this.recoveryCode.value }));
  }
}
