import { Action, createReducer, on } from '@ngrx/store';
import * as AuthModel from '@models/auth.model';
import { authActions } from '../actions';

export interface AuthState {
  loggingIn: boolean;
  loggedIn: boolean;
  awaitingMFA: boolean;
  redirectPath: string | null;
  settingPassword: boolean;
  changingPassword: boolean;
  passwordChanged: boolean;
  generatingTOTPData: boolean;
  confirmingToken: boolean;
  disablingTOTP: boolean;
  validatingToken: boolean;
  validatingRecoveryCode: boolean;
  recoveryCodeUsed: boolean;
  sendingRecoveryEmail: boolean;
  userData: AuthModel.UserData;
  userTokenData: AuthModel.UserTokenData;
  userTokenDataFetching: boolean;
  editingMyAccount: boolean;
  signingUp: boolean;
}

export const initialState: AuthState = {
  loggingIn: false,
  loggedIn: false,
  awaitingMFA: false,
  redirectPath: null,
  settingPassword: false,
  changingPassword: false,
  passwordChanged: false,
  generatingTOTPData: false,
  confirmingToken: false,
  disablingTOTP: false,
  validatingToken: false,
  validatingRecoveryCode: false,
  recoveryCodeUsed: false,
  sendingRecoveryEmail: false,
  userData: null,
  userTokenData: null,
  userTokenDataFetching: false,
  editingMyAccount: false,
  signingUp: false,
};

const reducer = createReducer(
  initialState,
  on(authActions.login,
    (state, action) => ({
      ...state,
      loggingIn: true,
      redirectPath: action.redirectPath,
    })),
  on(authActions.loginSuccess,
    (state, action) => ({
      ...state,
      loggingIn: false,
      loggedIn: true,
      userData: action.loginResponse.user,
    })),
  on(authActions.loginFirstStepSuccess,
    (state) => ({
      ...state,
      loggingIn: false,
      loggedIn: false,
      awaitingMFA: true,
    })),
  on(authActions.loginFail,
    (state) => ({
      ...state,
      loggingIn: false,
      loggedIn: false,
      redirectPath: null,
    })),
  on(authActions.logout,
    (state) => ({
      ...state,
      loggedIn: false,
      userData: null,
    })),
  on(authActions.expiredTokenLogout,
    (state) => ({
      ...state,
      loggedIn: false,
      userData: null,
    })),

  on(authActions.initLogin,
    (state) => ({
      ...state,
      loggingIn: true,
    })),
  on(authActions.initLoginSuccess,
    (state, action) => ({
      ...state,
      loggedIn: true,
      loggingIn: false,
      userData: action.loginResponse.user,
    })),
  on(authActions.initLoginFail,
    (state) => ({
      ...state,
      loggedIn: false,
      loggingIn: false,
    })),

  on(authActions.setPassword,
    (state) => ({
      ...state,
      settingPassword: true,
    })),
  on(authActions.setPasswordSuccess,
    (state) => ({
      ...state,
      settingPassword: false,
    })),
  on(authActions.setPasswordFail,
    (state) => ({
      ...state,
      settingPassword: false,
    })),

  on(authActions.generateTOTPData,
    (state) => ({
      ...state,
      generatingTOTPData: true,
    })),
  on(authActions.generateTOTPDataSuccess,
    (state) => ({
      ...state,
      generatingTOTPData: false,
    })),
  on(authActions.generateTOTPDataFail,
    (state) => ({
      ...state,
      generatingTOTPData: false,
    })),
  on(authActions.confirmTOTP,
    (state) => ({
      ...state,
      confirmingToken: true,
    })),
  on(authActions.confirmTOTPSuccess,
    (state) => ({
      ...state,
      confirmingToken: false,
      userData: {
        ...state.userData,
        MFAenabled: true,
      },
    })),
  on(authActions.confirmTOTPFail,
    (state) => ({
      ...state,
      confirmingToken: false,
    })),
  on(authActions.disableTOTP,
    (state) => ({
      ...state,
      disablingTOTP: true,
    })),
  on(authActions.disableTOTPSuccess,
    (state) => ({
      ...state,
      disablingTOTP: false,
      userData: {
        ...state.userData,
        MFAenabled: false,
      },
    })),
  on(authActions.disableTOTPFail,
    (state) => ({
      ...state,
      disablingTOTP: false,
    })),
  on(authActions.validateTOTP,
    (state) => ({
      ...state,
      validatingToken: true,
    })),
  on(authActions.validateTOTPSuccess,
    (state, action) => ({
      ...state,
      validatingToken: false,
      loggedIn: true,
      userData: action.loginResponse.user,
      awaitingMFA: false,
    })),
  on(authActions.validateTOTPFail,
    (state) => ({
      ...state,
      validatingToken: false,
    })),
  on(authActions.validateRecoveryCode,
    (state) => ({
      ...state,
      validatingRecoveryCode: true,
    })),
  on(authActions.validateRecoveryCodeSuccess,
    (state, action) => ({
      ...state,
      validatingRecoveryCode: false,
      loggedIn: true,
      userData: action.loginResponse.user,
      awaitingMFA: false,
      recoveryCodeUsed: true,
    })),
  on(authActions.validateRecoveryCodeFail,
    (state) => ({
      ...state,
      validatingRecoveryCode: false,
    })),
  on(authActions.userNotifiedAboutDisabledMFA,
    (state) => ({
      ...state,
      recoveryCodeUsed: false,
    })),
  on(authActions.clearTOTPAuthToken,
    (state) => ({
      ...state,
    })),
  on(authActions.clearTOTPAuthTokenSuccess,
    (state, action) => ({
      ...state,
      awaitingMFA: false,
    })),
  on(authActions.clearTOTPAuthTokenFail,
    (state) => ({
      ...state,
    })),
  on(authActions.changePassword,
    (state) => ({
      ...state,
      changingPassword: true,
    })),
  on(authActions.changePasswordSuccess,
    (state) => ({
      ...state,
      changingPassword: false,
      passwordChanged: true,
    })),
  on(authActions.changePasswordFail,
    (state) => ({
      ...state,
      changingPassword: false,
    })),
  on(authActions.changePasswordReset,
    (state) => ({
      ...state,
      passwordChanged: false,
    })),

  on(authActions.forgotPassword,
    (state) => ({
      ...state,
      sendingRecoveryEmail: true,
    })),
  on(authActions.forgotPasswordSuccess,
    (state) => ({
      ...state,
      sendingRecoveryEmail: false,
    })),
  on(authActions.forgotPasswordFail,
    (state) => ({
      ...state,
      sendingRecoveryEmail: false,
    })),

  on(authActions.fetchUserTokenData,
    (state) => ({
      ...state,
      userTokenDataFetching: true,
    })),
  on(authActions.fetchUserTokenDataSuccess,
    (state, action) => ({
      ...state,
      userTokenData: action.userTokenData,
      userTokenDataFetching: false,
    })),
  on(authActions.fetchUserTokenDataFail,
    (state) => ({
      ...state,
      userTokenDataFetching: false,
    })),

  on(authActions.editMyAccount,
    (state) => ({
      ...state,
      editingMyAccount: true,
    })),
  on(authActions.editMyAccountSuccess,
    (state, action) => ({
      ...state,
      editingMyAccount: false,
      userData: { ...state.userData, ...action.userData },
    })),
  on(authActions.editMyAccountFail,
    (state) => ({
      ...state,
      editingMyAccount: false,
    })),

  on(authActions.signUp,
    (state) => ({
      ...state,
      signingUp: true,
    })),
  on(authActions.signUpSuccess,
    (state) => ({
      ...state,
      signingUp: false,
    })),
  on(authActions.signUpFail,
    (state) => ({
      ...state,
      signingUp: false,
    })),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
