import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@modules/shared/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class RateLimitingExceedingDialogService {
  constructor(private dialog: MatDialog) { }
  public open(): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: '400px',
      data: {
        title: `To protect your account, we've temporarily paused authentication attempts. 
                Please wait 5 minutes and try again.`,
        withDenyButton: false,
      },
      panelClass: 'confirm-dialog',
      disableClose: true,
    }).afterClosed();
  }
}
