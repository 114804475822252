<auth-base-container title="Recovery code">
  <div content>
    <span class="description">
      Please provide your recovery code.
    </span>
    <a
      class="redirection-link"
      routerLink="/authentication-code"
    >
      Click here if you want to use authentication code from your authenticator device.
    </a>
    <form
      class="form"
      [formGroup]="recoveryLoginForm"
      [@.disabled]="true"
      (submit)="submit()"
    >
      <mat-form-field
        floatLabel="always"
        appearance="fill"
        class="text-input"
        data-id="password"
        hideRequiredMarker
      >
        <mat-label>
          <span>Recovery code</span>
        </mat-label>
        <input
          matInput
          id="recoveryInput"
          type="text"
          maxlength="100"
          formControlName="recoveryCode"
          placeholder="Your recovery code here"
          required
        >
        <mat-error *ngIf="recoveryCode.touched && recoveryCode.errors?.required">
          Recovery code is required
        </mat-error>
        <mat-error *ngIf="recoveryCode.touched && recoveryCode.errors?.maxlength">
          Recovery code cannot be longer than 100 characters
        </mat-error>
      </mat-form-field>
      <div class="submit">
        <ros-button
          type="submit"
          data-id="submit"
          [isDisabled]="(validationRecoveryCode$ | async) || !recoveryLoginForm.valid"
          [isLoading]="(validationRecoveryCode$ | async) || false"
          size="Large"
          width="Wide"
          title="Verify recovery code"
        ></ros-button>
      </div>
    </form>
  </div>
  <a
    routerLink="/admin"
    footer
  >
    <span>Want to Log in?</span>
    <span>Take me back</span>
  </a>
</auth-base-container>