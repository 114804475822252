

<div class="dialog">
   <div class="dialog__title">
    {{dialogData.title}}
   </div>
   <div *ngIf="dialogData.subtitle" class="dialog__subtitle" [innerHTML]="dialogData.subtitle"></div>
   <div *ngIf="dialogData.description" class="dialog__description">
      {{dialogData.description}}
   </div>
   <div class="dialog__actions">
      <ros-button 
                  *ngIf='dialogData.withDenyButton'
                  (onClick)="deny()"
                  width="MediumShort"
                  variant="Ternary"
                  type="Info"
                  title="No">
      </ros-button>
      <ros-button (onClick)="confirm()"
                  width="MediumShort"
                  [title]="dialogData.withDenyButton ? 'Yes' : 'Ok'">
      </ros-button>
   </div>
</div>
