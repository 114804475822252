import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@modules/shared/components/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable()
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) { }
  public confirm({
    title,
    subtitle,
    description,
    width = 370,
    withDenyButton = true,
  }:{
    title: string,
    subtitle?: string,
    description?: string,
    width?: number,
    withDenyButton?: boolean
  }): Observable<boolean> {
    return this.dialog.open(ConfirmDialogComponent, {
      width: `${width}px`,
      data: {
        title, subtitle, description, withDenyButton,
      },
      panelClass: 'confirm-dialog',
      disableClose: true,
    }).afterClosed();
  }
}
