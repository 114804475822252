import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { RosalynComponentsModule } from 'rosalyn-components';
import { MaterialModule } from '../material/material.module';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthLoginComponent } from './components/auth-login/auth-login.component';
import { SetPasswordComponent } from './components/set-password/set-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { InstantLoginDialogComponent } from './components/instant-login-dialog/instant-login-dialog.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { LearnersLoginComponent } from './components/learners-login/learners-login.component';
import { TotpLoginComponent } from './components/totp-login/totp-login.component';
import { AuthBaseContainerComponent } from './containers/auth-base-component/auth-base-container.component';
import { RecoveryLoginComponent } from './components/recovery-login/recovery-login.component';

@NgModule({
  declarations: [
    AuthLoginComponent,
    TotpLoginComponent,
    SetPasswordComponent,
    ForgotPasswordComponent,
    InstantLoginDialogComponent,
    SignUpComponent,
    LearnersLoginComponent,
    AuthBaseContainerComponent,
    RecoveryLoginComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    RosalynComponentsModule,
    FormsModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    SharedModule,
  ],
})
export class AuthModule { }
