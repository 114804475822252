import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CookieService } from '@services/cookie.service';
import { SessionStorageService } from '@services/session-storage.service';
import { LocalStorageService } from '@services/local-storage.service';
import { AuthService } from '@services/auth.service';
import { RoleAccessService } from '@services/role-access.service';
import { WindowService } from '@services/window.service';
import { RedirectService } from '@services/redirect.service';
import { ConfirmDialogService } from '@services/confirm-dialog.service';
import { RateLimitingExceedingDialogService } from '@services/rate-limiting-exceeding-dialog.service';
import { BasicDialogService } from '@services/basic-dialog.service';
import { CompanyService } from '@services/company.service';
import { LearnersService } from '@services/learners.service';
import { CertificatesService } from '@services/certificates.service';
import { ErrorDialogService } from '@services/error-dialog.service';
import { NotificationsService } from '@services/notifications.service';
import { RoleGuard } from './guards/role.guard';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
  ],
  providers: [
    AuthGuard,
    RoleGuard,
    CookieService,
    SessionStorageService,
    LocalStorageService,
    AuthService,
    RoleAccessService,
    WindowService,
    RedirectService,
    ConfirmDialogService,
    BasicDialogService,
    ErrorDialogService,
    CompanyService,
    LearnersService,
    CertificatesService,
    NotificationsService,
    RateLimitingExceedingDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule { }
