import { FormGroup } from '@angular/forms';

export class FormUtils {
  public static fullReset(form: FormGroup): void {
    form.reset({}, { emitEvent: false });

    Object.keys(form.controls).forEach((key) => {
      const control = form.get(key);
      control.updateValueAndValidity();
    });
    form.markAsUntouched();
    form.markAsPristine();
  }
}
