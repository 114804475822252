import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
@Component({
  selector: 'ros-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements AfterViewInit {
  @ViewChild('button') buttonElement!: ElementRef;

  @Input() public variant: 'Primary' | 'Secondary' | 'Ternary' | 'Outline' = 'Primary';
  @Input() public type: 'Info' | 'Warning' | 'Error' | 'Neutral' = 'Neutral';
  @Input() public icon: string = 'None';
  @Input() public iconNoSeparator: boolean = false;
  @Input() public width?: '' | 'Short' | 'MediumShort' | 'Wide' = '';
  @Input() public iconPosition?: 'Left' | 'Right' | 'Center' = 'Left';
  @Input() public title!: string;
  @Input() public subtitle?: string;
  @Input() public notificationsCount?: string;
  @Input() public isDisabled?: boolean = false;
  @Input() public size?: 'Regular' | 'Large' | 'Large-wide' = 'Regular';
  @Input() public ariaLabel? = '';
  @Input() public extendedWidth? = false;
  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    if (value) {
      this.keepInitialWidth();
    }
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  @Output() public onClick = new EventEmitter<Event>();

  private initialWidth: string = '';
  private _isLoading = false;

  public ngAfterViewInit() {
    const button = this.buttonElement?.nativeElement;
    if (button && !this.initialWidth) {
      this.initialWidth = `${button.offsetWidth}px`;
    }
  }

  public handleClick(ev: Event): void {
    if (!this.isDisabled) {
      this.onClick.emit(ev);
    }
  }

  private keepInitialWidth() {
    const button = this.buttonElement?.nativeElement;
    if (button && this.initialWidth) {
      button.style.width = this.initialWidth;
    }
  }
}
